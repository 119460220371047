.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.multi-range-slider{
  box-shadow: none!important;
  border: none!important;
  padding: 10px 0px!important;
}

.multi-range-slider .bar-left, .multi-range-slider .bar-right, .multi-range-slider .bar-inner{
  box-shadow: none!important;
  border: none!important;
}
.multi-range-slider .thumb::before{
  box-shadow: none!important;
  border: none!important;
}
.multi-range-slider .thumb .caption *{
  color: #000!important;
  background-color: #fff!important;
  border: none!important;
  box-shadow: 0px 0px 2px black!important;
  padding: 0 10px!important;
}
.multi-range-slider .bar {
  height: 4px;
}